<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container pt-4">
            <div class="row">
                <div class="col-3">
                    <h4 class="h4">General</h4>
                    <ul>
                        <li><router-link :to="{ path: '/admin/users' }">Users</router-link></li>
                        <li><router-link :to="{ path: '/admin/orders' }">Orders</router-link></li>
                        <li><router-link :to="{ path: '/admin/settings' }">Settings</router-link></li>
                    </ul>
                </div>
                <div class="col-3">
                    <h4 class="h4">Content</h4>
                    <ul>
                        <li><router-link :to="{ path: '/admin/photos' }">Photos</router-link></li>
                        <li><router-link :to="{ path: '/admin/videos' }">Videos</router-link></li>
                        <li><router-link :to="{ path: '/admin/emails' }">Emails</router-link></li>
                        <li><router-link :to="{ path: '/admin/packages' }">Packages</router-link></li>
                        <li><router-link :to="{ path: '/admin/faq' }">Faq</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import navAdmin from './../components/nav-admin';

    export default {
        components: {
            navAdmin
        }
    }
</script>
